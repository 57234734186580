import Vue from "vue";
import Router from "vue-router";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import Main from "./pages/Main.vue";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/",
            name: "main",
            components: { default: Main, header: MainNavbar, footer: MainFooter },
            props: { header: { colorOnScroll: 450 } }
        },
       
    ],
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
});

export default router;
