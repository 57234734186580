<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax class="page-header-image" style="background-image: url('img/bg32.jpg')"></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">Obsidian Solutions</h1>
            <h4>Soluciones a medida para PYMEs</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="about-description text-center">
        <div class="features-3">
          <div class="container">
            <div class="row">
              <div class="col-md-8 mr-auto ml-auto">
                <h2 class="title">Simpler. Smarter. Faster.</h2>
                <h4
                  class="description"
                >Con la ayuda de Obsidian Solutions, podrás ser más competitivo, automatizar trabajo y disponer de más tiempo para lo realmente importante, Tu Negocio.</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="info info-hover">
                  <div class="icon icon-success icon-circle">
                    <i class="now-ui-icons tech_watch-time"></i>
                  </div>
                  <h4 class="info-title">Automatización de Procesos</h4>
                  <p class="description">Aumenta la productividad de tu negocio con nosotros.</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="info info-hover">
                  <div class="icon icon-info icon-circle">
                    <i class="now-ui-icons education_atom"></i>
                  </div>
                  <h4 class="info-title">Crawling / Data Mining</h4>
                  <p
                    class="description"
                  >Somos expertos en la recogida de datos Web y en automatizar flujos Web.</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="info info-hover">
                  <div class="icon icon-primary icon-circle">
                    <i class="now-ui-icons objects_globe"></i>
                  </div>
                  <h4 class="info-title">Gestión de infraestructura IT</h4>
                  <p
                    class="description"
                  >Te ayudamos a gestionar tus recursos IT y a llevartelos a la nube.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="separator-line separator-primary"></div>
      <div class="projects-5">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">Algunos de nuestros Proyectos</h2>
              <h4
                class="description"
              >En Obsidian Solutions colaboramos con nuestros clientes en proyectos de gestion de Infraestructura, recopilacion de datos de plataformas web y en el desarrollo y gestion de APIs de acceso a información.</h4>
              <div class="section-space"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 ml-auto">
              <card
                type="background"
                raised
                class="card-background-product"
                style="background-image: url('img/crawler.jpg')"
              >
                <h2 class="card-title">Captura de datos de Plataformas Web</h2>
                <p
                  class="card-description"
                >Captura de datos de diferentes Webs de alojamiento, directorios de empresas, negocios y puntos de interés.</p>
                <badge type="neutral">Web Scrapping</badge>
              </card>
            </div>
            <div class="col-md-5 mr-auto">
              <info-section
                type="danger"
                icon="now-ui-icons ui-2_chat-round"
                title="Recogida de datos diaria"
                description="Automatizamos la recogida de datos diaria."
              />

              <info-section
                type="danger"
                icon="now-ui-icons business_chart-pie-36"
                title="Reportes a medida"
                description="Creamos detallados reportes HTML o en CSV para la consulta del correcto funcionamiento."
              />

              <info-section
                type="danger"
                icon="now-ui-icons design-2_ruler-pencil"
                title="Mas de 5 millones de datos diarios en todo el mundo"
                description="Recogemos diariamente mas de 5 millones de datos, demostrando el buen rendimiento de nuestros desarrollos."
              />
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-5 ml-auto mt-5">
              <info-section
                type="warning"
                icon="now-ui-icons users_single-02"
                title="Dedícate a lo que realmente importa"
                description="Automatizando las tareas de poco valor añadido, permitimos a nuestros clientes dedicarse a lo que realmente tienen que hacer, aportar soluciones de valor."
              />

              <info-section
                type="warning"
                icon="now-ui-icons business_chart-bar-32"
                title="Aumenta la productividad"
                description="Automatizando la generación de informes o la carga de datos a plataformas web, se puede ser más eficiente."
              />

              <info-section
                type="warning"
                icon="now-ui-icons arrows-1_refresh-69"
                title="Evolucion constante"
                description="Nos adaptamos a los cambios o a las nuevas necesidades y extendemos nuestros desarrollos."
              />
            </div>
            <div class="col-md-5 mr-auto">
              <card
                type="background"
                raised
                class="card-background-product"
                style="background-image: url('img/project9.jpg')"
              >
                <h2 class="card-title">Automatización de Procesos.</h2>
                <p class="card-description text-white">
                  Generacion automática de informes, carga de datos a plataformas web a traves de APIs.
                </p>
                <badge type="neutral">Automation</badge>
              </card>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-5 ml-auto">
              <card
                type="background"
                raised
                class="card-background-product"
                style="background-image: url('img/learning.jpg')"
              >
                <h2 class="card-title">Formación Continua</h2>
                <p
                  class="card-description"
                >Impartimos formación orientada a Desarrolladores.</p>
                <badge type="neutral">Learning</badge>
              </card>
            </div>
            <div class="col-md-5 mr-auto">
              <info-section
                type="danger"
                icon="now-ui-icons ui-2_chat-round"
                title="Formadores con experiencia docente"
                description="Nos adaptamos a tus necesidades."
              />

              <info-section
                type="danger"
                icon="now-ui-icons business_chart-pie-36"
                title="Docker"
                description="Los contendores han llegado para quedarse, no te quedes atrás."
              />

              <info-section
                type="danger"
                icon="now-ui-icons design-2_ruler-pencil"
                title="Metodologías Ágiles"
                description="Mejora los plazos de entrega, mejora el compromiso y aumenta la productividad de tu equipo."
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="about-team team-4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">We are nerd rockstars</h2>
              <h4 class="description">
                This is the paragraph where you can write more details about
                your team. Keep you user engaged by providing meaningful information.
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card type="profile" plain>
                <div slot="raw-content" class="row">
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img class="img img-raised rounded" src="img/olivia.jpg" />
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">Ella Evelyn</h4>
                      <h6 class="category">Air Crew Member</h6>
                      <p class="card-description">
                        Think in the morning. Act in the noon. Eat in the evening. Sleep in
                        the night......
                      </p>
                      <div class="card-footer">
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-twitter">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-facebook">
                          <i class="fab fa-facebook-square"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-google">
                          <i class="fab fa-google"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card type="profile" plain>
                <div slot="raw-content" class="row">
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img class="img img-raised rounded" src="img/emily.jpg" />
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">Mila Skylar</h4>
                      <h6 class="category">Architect</h6>
                      <p class="card-description">
                        Love cures people - both the ones who give it and the ones who
                        receive it...
                      </p>
                      <div class="card-footer">
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-linkedin">
                          <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-facebook">
                          <i class="fab fa-facebook-square"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-dribbble">
                          <i class="fab fa-dribbble"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-google">
                          <i class="fab fa-google"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card type="profile" plain>
                <div slot="raw-content" class="row">
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img class="img img-raised rounded" src="img/james.jpg" />
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">Daniel Carter</h4>
                      <h6 class="category">Aviation Inspector</h6>
                      <p class="card-description">
                        Keep your face always toward the sunshine - and shadows will fall
                        behind you...
                      </p>
                      <div class="card-footer">
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-youtube">
                          <i class="fab fa-youtube"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-twitter">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-instagram">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card type="profile" plain>
                <div slot="raw-content" class="row">
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img class="img img-raised rounded" src="img/michael.jpg" />
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">Dylan Wyatt</h4>
                      <h6 class="category">Conservation Scientist</h6>
                      <p class="card-description">
                        There is only one corner of the universe you can be certain of
                        improving, and that's your own self...
                      </p>
                      <div class="card-footer">
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-linkedin">
                          <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-facebook">
                          <i class="fab fa-facebook-square"></i>
                        </a>
                        <a href="#pablo" class="btn btn-icon btn-neutral btn-google">
                          <i class="fab fa-google"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>-->
      <!-- <div class="about-office">
        <div class="container">
          <div class="row text-center">
            <div class="col-md-8 ml-auto mr-auto">
              <h2 class="title">Our office is our second home</h2>
              <h4 class="description">
                Here are some pictures from our office. You can see the place
                looks like a palace and is fully equiped with everything you need to get the job
                done.
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <img class="rounded img-raised" alt="Raised Image" src="img/bg27.jpg" />
            </div>
            <div class="col-md-4">
              <img class="rounded img-raised" alt="Raised Image" src="img/bg24.jpg" />
            </div>
            <div class="col-md-4">
              <img class="rounded img-raised" alt="Raised Image" src="img/bg28.jpg" />
            </div>
            <div class="col-md-6">
              <img class="rounded img-raised" alt="Raised Image" src="img/project6.jpg" />
            </div>
            <div class="col-md-6">
              <img class="rounded img-raised" alt="Raised Image" src="img/project14.jpg" />
            </div>
          </div>
        </div>
      </div> -->
      <div class="about-contact">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
              <h2 class="text-center title">Quieres trabajar con Nosotros?</h2>
              <h4
                class="text-center description"
              >Escríbenos a info@obsidiansolutions.es con lo que necesitas y estaremos encantados de hablar contigo para empezar un proyecto juntos.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Badge,
  InfoSection,
  FormGroupInput,
  Checkbox
} from "@/components";
import { Select, Option } from "element-ui";

export default {
  name: "about-us",
  bodyClass: "about-us",
  components: {
    Card,
    InfoSection,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        speciality: ""
      }
    };
  }
};
</script>
<style>
</style>
